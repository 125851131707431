button{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  font-family: 'visby_cfextra_bold';
  background-color: #5092A4!important;

  color: white!important;;
  border: none;
  font-size: 14px;
  
}
button:focus{
  outline: none!important;
}
.btn-secondary {
  background-color: #5092A4!important;
}
input{
  height: 48px !important;
}