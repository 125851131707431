@import "../../styles/variables/_colors.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.top_banner {
  margin-bottom: 0px;
  text-align: center;
}
.top_banner::before{
  background: #f0f3f5;
  content: 'read';
  color: #f0f3f5;
  font-size: 12px;
  display: block;
  top: -17px;
  z-index: 9999
}
.p_banner  {
  width: 100%;
  padding: 15px;
  margin-bottom: 0;
  text-decoration: none;
  color: #fff;
}
.logo_container {
  align-items: center;
  display: flex;
}
.brand_image {
  margin: 0px 40px;
  cursor: pointer;
}
.max_width_100 {
  max-width: 80%;
  cursor: pointer;
}
.vl {
  border-left: 1px solid #e1e5e8;
  height: 100%;
}
nav {
  float: right;
  a {
    margin: 0 10px;
  }
  button {
    margin: 0 10px;
  }
}
.header {
  background: $white;
  min-height: 64px;
}
.align_right {
  flex-direction: row-reverse;
}
.breadcrumb_container {
  ol {
    background-color: $white !important;
    margin-bottom: 0;
  }
}
.my_breadcrumb_item + .my_breadcrumb_item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #adb5bd;
  content: ">";
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
}
.my_breadcrumb_item {
  color: $text_color;
  text-transform: uppercase;
  a {
    color: $primary_new;
  }
}
.header_profile_image {
  margin-right: 16px;
  height: 24px;
  margin-top: 2px;
}
.header_profile_box {
  margin: 8px 16px !important;
  color: $text_color;
  ::after {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    margin: 8px 10px !important;
  }
}
.header_profile_drop {
  margin-left: 15px;
  margin-top: 10px;
  height: 6px;
}
.dropdown-toggle::after {
  display: none;
}
.role_name {
  text-transform: capitalize;
  color: $regent_gray;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  .arrow_icon_wrap {
    margin-right: 5px;
  }
}
.user_name {
  font-size: 14px;
  font-weight: 700;
}
.user_profile_action {
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  font-family: "proximanova_regular";
  outline: none !important;
  box-shadow: none !important;
  &:hover,
  &:focus,
  &:active {
    background-color: #f8f9fa !important;
    color: #212529 !important;
  }
}

.user_profile_actions_divider {
  margin: 0 1rem;
}
.user_info_action {
  padding-bottom: 16px;
  display: none !important;
  cursor: auto;
  &:hover {
    background: $white;
  }
}
.user_info_action_divider {
  margin-bottom: 12px;
  display: none;
}
.logout_item {
  color: $burnt_sienna !important;
}
@include media-breakpoint-down(xs) {
  .header_profile_image {
    margin-right: 2px;
  }
  .header_profile_box {
    margin: 8px 0px;
  }
  .user_info_action,
  .user_info_action_divider {
    display: block !important;
  }
}
.nav_container2 {
  color: red;
}
.nav_container1 {
  color: pink;
  border-bottom: 1px solid #dee2e6;
}
.nav_with_sidebar {
  border-bottom: 1px solid #e1e5e8;
  .logo_wrap {
    border-right: 1px solid #e1e5e8;
    min-width: 240px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 30px;
    @include media-breakpoint-down(md) {
      min-width: 200px;
    }
    @include media-breakpoint-down(sm) {
      min-width: auto;
      border-right: unset;
      margin-right: 0;
    }
  }
}
.user_profile_innr_wrap {
  .searching_box {
    position: relative;
    display: inherit;
    margin: 0 20px 15px;
    width: calc(100% - 40px);
    .search_clear_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 41px;
      background-color: transparent !important;
      padding: 0;
      margin: 0;
      display: inline-flex;
      opacity: 0;
      visibility: hidden;
      outline: none !important;
      box-shadow: unset !important;
    }
    .search_clear_icon {
      &.value_in {
        opacity: 1;
        visibility: visible;
      }
    }

    .search_field {
      height: 32px !important;
      margin-right: 5px;
      padding-right: 35px !important;
      background: #f8f9fa !important;
      border: 1px solid #ced4da !important;
      box-sizing: border-box !important;
      border-radius: 20px !important;
      font-size: 14px;
      outline: none !important;
      box-shadow: none !important;
      ::placeholder {
        color: #868e96 !important;
      }
    }
    .listing_search_icon {
      height: 18px;
      background-repeat: no-repeat;
      background-position: center;
      border-left: 2px solid #ced4da;
      width: 30px;
      content: "";
      position: absolute;
      z-index: 30;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      svg {
        width: 12px;
      }
    }
  }
  .accordion_custom_list {
    .accordion_wrapper {
      .accordion_card {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        box-shadow: none;
        .accordion_header {
          padding: 0;
          background-color: transparent;
          border: none;
          .accordion_toggle {
            font-size: 14px;
            font-weight: 500;
            font-family: "proximanova_regular";
            color: #001737 !important;
            background-color: transparent !important;
            text-decoration: none !important;
            box-shadow: none !important;
            outline: none !important;
            width: 100%;
            padding: 8px 20px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-transform: capitalize;
            margin: 0;
            &:after {
              content: "";
              background: url("../../images/Market/plus-icon.svg") no-repeat;
              display: inline-block;
              width: 15px;
              height: 15px;
              background-size: contain;
            }
            &.iconToggle {
              &:after {
                background: url("../../images/Market/minus-bar-icon.svg")
                  no-repeat;
              }
            }
          }
        }
        .accordion_list_wrap {
          max-height: 200px;
          overflow: auto;
          li {
            padding: 10px 0 0;
            a {
              font-size: 12px;
              font-weight: 500;
              font-family: "proximanova_regular";
              color: #001737d6 !important;
              text-decoration: none !important;
              display: inline-block;
              &:hover {
                color: rgb(80, 146, 164) !important;
              }
            }
          }
        }
      }
    }
  }
}
