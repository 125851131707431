@import "styles/styles.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#search::placeholder {
  color: rgb(175, 182, 192);
  opacity: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dropdown-toggle::after {
  display: none !important;
}

// .locations_table_listing .table_row_count p {
//   display: inline-block;
//   width: 18px;
//   height: 18px;
//   background: #5092a4;
//   border-radius: 4px;
//   top: 118px;
//   margin-left: 10px;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 14px;
//   letter-spacing: 0.03em;
//   padding-left: 6px;
//   padding-top: 2px;
//   color: #ffffff;
// }
.MuiDialog-paperWidthSm {
  border-radius: 8px !important;
  max-width: 400px !important;
  p {
    font-weight: 300;
    line-height: 1.2;
    font-family: inherit !important;
    margin-top: -10px;
    font-size: 16px;
    margin-bottom: 0px;
  }
}
.delete-dialogue-radius {
  border-radius: 10px;
}
.cancel-button-popup {
  color: black !important;
  background-color: white !important;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "visby_cfextra_bold";
}
.submit-delete-button {
  padding: 10px 25px !important;
  border-radius: 20px !important;
  margin-left: 35px !important;
}
.buttons-main-div {
  margin-right: 20px;
  margin-bottom: 10px;
}
.table-div {
  @media (max-width: 630px) {
    overflow-x: scroll;
  }
}

.generic_form {
  form div {
    margin-top: 10px;
  }
  input:not(input:disabled) {
    height: 48px;
    /* white */
    background: #ffffff !important;
    /* Grays/Gray 4 */
    border: 1px solid #ced4da;
    box-sizing: border-box !important;
    border-radius: 4px;
  }
  ::placeholder {
    color: #868e96 !important;
    font-weight: 300;
  }
  select {
    height: 48px !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
  }
}
.form-control:disabled {
  background-color: #F2F2F2 !important;
}
.css-2b097c-container div {
  margin-top: 0px !important;
  // padding: 1px 5px !important;
}
.css-2b097c-container {
  margin-top: 0px !important;
}
.e-sidebar.e-right.e-close {
  box-shadow: none;
  transform: none;
  visibility: visible;
}
.css-133bw85-Input {
  height: 48px;
}
.form-control-file {
  cursor: pointer;
}
.forms_loader {
  background: #fff;
  height: calc(100vh - 64px);
}
.css-xb97g8 {
  cursor: pointer;
}
.role_form_wrapper {
  .custom-control {
    display: block !important;
    position: static !important;
    &.custom-control-inline {
      display: inline-block !important;
      margin-top: 4px !important;
    }
  }
  .roles_checkboxes_box {
    margin-top: 0 !important;
  }
  .per_name_label {
    display: block !important;
    margin-bottom: 10px;
  }
  .custom-control.custom-checkbox.mb-3 {
    margin: 4px 0 0!important;
    min-height: auto !important;
    line-height: normal;
  }
}

.collapse.show {
    background: #f0f3f5;
    box-shadow: inset 0px 0px 6px #0000002b;
}
.orderThrottling_btn_toggle {
  background-color: #5092a4;
}
#dropdown-basic {
  background-color: #5092a4 !important;
}

//Styles for datasheet
.custom-datasheet {
  display: block;
  .data-grid-container{
    width: 100%;
    display: block;
    .data-grid{
      width: 100%;
      thead {
        th {
          border: 1px solid #DDD;
          padding: 2px;
        }
      }
      tbody {
        td {
          padding: 2px;
        }
      }
      .read-only {
        &.cell {
          background: #f8f9fa;
          color: #000;
        }
      }
      .editing {
        .data-editor {
          width: 100%;
          border: none;
        }
      }
      .value-viewer {
        text-align: center;
      }
      .data-editor {
        text-align: left;
      }
    }
  }
}