$primary_new : rgb(80, 146, 164);
$white : #FFFFFF;
$gray : #ADB5BD;
$text_color : #001737;
$dove_gray : #6E6E6E;
$blue_dianne : #224C57;
$porcelain : #EDEEEF;
$jagged_ice :  #BBE2E5;
$jagged_ice :  #BBE2E5;
$mercury : #E5E5E5;
$red_error :  #d9534f;
$casal : #2F5E6A;
$regent_gray : #808B9B;
$burnt_sienna : #EE5D5D;
$jaffa : #F27E52;
$athens_gray : #F8F9FA;
$geyser : #DEE2E6;
$primary-color: #5092a4;
$dark-blue: #4c86a0;