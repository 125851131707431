a {
  font-family: 'visby_cfextra_bold';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 16px;
  color: #001737;
  cursor: pointer;
}
li {
  font-family: 'visby_cfextra_bold';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 16px;
}
h1 {
  font-family: 'visby_cfextra_bold';
  font-style: normal;
  font-weight: 800;
  font-size: 78px;
}
h3 {
  font-family: 'visby_cfextra_bold';
  font-style: normal;
  font-weight: 300;
  font-size: 20px!important;
}
p {
  font-family: 'proximanova_regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
label {
  font-family: 'proximanova_regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 0px;
}
span {
  font-family: 'proximanova_regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}