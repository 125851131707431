@font-face {
  font-family: 'visby_cf_bold';
  src: url('../../fonts/VisbyCF-Bold.woff2') format("woff2"), url('../../fonts/VisbyCF-Bold.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_bold_oblique';
  src: url('../../fonts/VisbyCF-BoldOblique.woff2') format("woff2"), url('../../fonts/VisbyCF-BoldOblique.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_demi_bold';
  src: url('../../fonts/VisbyCF-DemiBold.woff2') format("woff2"), url('../../fonts/VisbyCF-DemiBold.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_demi_bold_oblique';
  src: url('../../fonts/VisbyCF-DemiBoldOblique.woff2') format("woff2"), url('../../fonts/VisbyCF-DemiBoldOblique.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cfextra_bold';
  src: url('../../fonts/VisbyCF-ExtraBold.woff2') format("woff2"), url('../../fonts/VisbyCF-ExtraBold.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cfextra_bold_oblique';
  src: url('../../fonts/VisbyCF-ExtraBoldOblique.woff') format("woff"), url('../../fonts/VisbyCF-ExtraBoldOblique.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_heavy_oblique';
  src: url('../../fonts/VisbyCF-HeavyOblique.woff2') format("woff2"), url('../../fonts/VisbyCF-HeavyOblique.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_light';
  src: url('../../fonts/VisbyCF-Light.woff2') format("woff2"), url('../../fonts/VisbyCF-Light.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_light_oblique';
  src: url('../../fonts/VisbyCF-LightOblique.woff2') format("woff2"), url('../../fonts/VisbyCF-LightOblique.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_medium';
  src: url('../../fonts/VisbyCF-Medium.woff2') format("woff2"), url('../../fonts/VisbyCF-Medium.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_medium_oblique';
  src: url('../../fonts/VisbyCF-MediumOblique.woff2') format("woff2"), url('../../fonts/VisbyCF-MediumOblique.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_thin';
  src: url('../../fonts/VisbyCF-Thin.woff2') format("woff2"), url('../../fonts/VisbyCF-Thin.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'visby_cf_thin_oblique';
  src: url('../../fonts/VisbyCF-ThinOblique.woff2') format("woff2"), url('../../fonts/VisbyCF-ThinOblique.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proximanova_regular';
  src: url('../../fonts/ProximaNova-Regular.woff') format("woff"), url('../../fonts/ProximaNova-Regular.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'trade_gothic_ltstd_light';
  src: url('../../fonts/TradeGothicLTStd-Light.woff') format("woff"), url('../../fonts/TradeGothicLTStd-Light.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}