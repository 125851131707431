@import "../../styles/variables/_colors.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.breadcrumb_text {
    font-size: 11px !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    color: #0000008A !important;
}
.breadcrumb_text_selected {
    font-family: 'visby_cfextra_bold' !important;
    font-size: 11px !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    color: #5092a4;
}
.sidebar_breadcrumb {
    left: 70px;
}
@media(max-width: 768px) {
    .breadcrumb_box{
        position: absolute;
        margin-top: 100px;
        margin-left: 10px;
        width: 120%;
        z-index: 9;
        a{
            font-size: 10px!important;
        }
        p{
            font-size: 10px!important;
        }
        svg{
            width: 0.5em;
        }
        nav{
            float: left;
        }
    }
}
.breadcrumb_text_dashboard{
    @media(max-width: 380px) {
        display: none;
    }
}