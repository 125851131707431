@import "~bootstrap/scss/bootstrap.scss";

@import "/variables/_colors.scss";
@import "/base/_fonts.scss";
@import "/base/_typography.scss";
@import "/base/_elements.scss";

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
.body-highlight {
  border: 6px solid #ee5d5d;
}

.user_profile_actions_list {
  height: 100%;
  &.active-profile {
    background: #f1f3f5;
  }
  .dropdown-menu.show {
    right: 2px !important;
    left: auto !important;
    width: 240px !important;
    top: 70px !important;
    transform: translate(0px, 0px) !important;
    z-index: 99999;
    box-shadow: 0px 4px 12px rgba(184, 187, 209, 0.15);
    border-radius: 8px;
    border: none;
  }
  @include media-breakpoint-down(sm) {
    .dropdown-toggle.nav-link {
      padding: 8px;
    }
  }
}

.slider-close-button {
  float: right;
  width: 26px;
  height: 20px;
  font-size: 10px;
  box-shadow: none;
  padding: 1px;
  background-color: #e9ecef !important;
  border-radius: 5px;
  color: #615b5b !important;
  margin: 10px;
}
.e-content-animation {
  margin-right: 0px !important;
}
form {
  span {
    display: block;
    color: red;
  }
}

button:focus {
  outline: none !important;
}
.Toastify__toast {
  min-height: 48px !important;
}
.Toastify__toast--error,
.Toastify__toast--success {
  background: $text_color !important;
}
.Toastify__progress-bar {
  background-color: $jaffa !important;
}
.Toastify__close-button {
  color: #fff !important;
  background: none !important;
  font-size: 23px !important;
  opacity: 1 !important;
  font-weight: 100 !important;
  margin-top: -2px;
  outline: none !important;
}
.Toastify__toast-body {
  font-size: 15px;
}
.Toastify__close-button:focus {
  outline: none !important;
}
::-webkit-scrollbar {
  width: 2px; /* for vertical scrollbars */
  height: 2px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(80, 146, 164, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(80, 146, 164, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.crud_body {
  min-height: calc(100vh - 64px);
  background: $athens_gray !important;
  position: relative;
  overflow: hidden;
}
.listing_table {
  border: none;
  thead {
    tr {
      background: transparent;
      th {
        border-bottom: none !important;
        background: transparent;
        border-top: 1px solid #dee2e6 !important;
        border-bottom: 1px solid #dee2e6;
        box-shadow: 0px 0px 0px 0px #dee2e6, 0px -1px 0px 0px #dee2e6;
      }
    }
  }
}
.custom-control-label {
  font-size: 14px;
  color: $regent_gray !important;
  &:before {
    cursor: pointer !important;
    border: $primary_new solid 1px !important;
    margin-top: -4px;
  }
  &:after {
    cursor: pointer !important;
    margin-top: -4px;
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary_new !important;
}
.custom-control-input:checked ~ .custom-control-label {
  color: $text_color !important;
}
.custom-control {
  padding-left: 1.7rem !important;
}
.custom-control.custom-checkbox.mb-3 {
  margin: 4px 0 12px !important;
}
.MuiTooltip-tooltip {
  background-color: #f8f9fa !important;
  padding: 0px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}
// .css-b8ldur-Input {
//   height: 30px;
//   padding: 0px!important;
//   input{
//     height: 40px !important;
//   }
// }
.react-select__input {
  height: 35px !important;
  padding: 0px !important;
  input {
    height: 40px !important;
  }
}
.page_size_dropdown {
  border: none;
  background: #f8f9fa;
  outline: none;
  width: fit-content !important;
  padding: 0;
  font-size: 14px;
  margin-left: 0 !important;
  margin-right: 4px;
}
.select-link-style.custom-select {
  color: #808b9b;
  font-size: 14px;
  background: transparent;
  text-align: center;
  text-decoration: underline;
  outline: none;
  width: 25px;
  padding: 0;
  font-size: 14px;
  border: none;
  cursor: pointer;
}
.select-link-style.custom-select:focus,
.select-link-style.custom-select:active {
  box-shadow: none;
  outline: none;
  color: #808b9b;
  background: transparent;
}
.listing_table thead tr th:first-child {
  border-top-left-radius: 4px;
  box-shadow: -1px 0px 0px 0px #dee2e6, 0px -1px 0px 0px #dee2e6;
  // border-top: none !important;
  // background: #e9ecef;
}
.listing_table thead tr th:last-child {
  border-top-right-radius: 4px;
  box-shadow: 1px 0px 0px 0px #dee2e6, 0px -1px 0px 0px #dee2e6;
  // border-top: none !important;
}
.listing_table tbody tr td:first-child {
  box-shadow: -1px 0 0px #dee2e6;
}
.listing_table tbody tr:first-child {
  box-shadow: 0px 0px 0px 0px #dee2e6, 0px -0.5px 0px 0px #dee2e6;
}
.listing_table tbody tr td:last-child {
  box-shadow: 1px 0 0px #dee2e6;
}
.listing_table tbody tr:last-child td:first-child {
  box-shadow: -1px 0px 1px 0px #dee2e6;
  border-bottom-left-radius: 4px;
  border-top: 1px solid #dee2e6;
  border-left: unset;
}
.listing_table tbody tr:last-child {
  border-bottom: 1px solid #dee2e6;
}
.listing_table tbody tr:last-child td:last-child {
  border: none;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 0px 0px #dee2e6, 1px 0px 0px 0px #dee2e6;
}

.MuiTypography-h6 {
  font-family: "visby_cfextra_bold" !important;
}
.MuiDialog-paperWidthSm p {
  font-family: "visby_cf_medium" !important;
}
.MuiDialog-paperWidthSm {
  overflow-y: unset !important;
}
.location-slider-thumb-1 {
  padding: 1px 5px;
  background-color: rgb(222, 226, 230);
  /* color: rgb(128, 139, 155) !important; */
  color: #001737;
  cursor: pointer;
}
.location-slider-thumb-0 {
  padding: 1px 5px;
  background-color: rgb(222, 226, 230);
  /* color: rgb(128, 139, 155) !important; */
  color: #001737;
  cursor: pointer;
}
.location-slider-track-1 {
  background-color: #5092a4;
  height: 2px;
  margin-top: 8px;
}
.location-slider-track-2 {
  background-color: #dee2e6;
  height: 2px;
  margin-top: 8px;
}
.location-slider-track-0 {
  background-color: #dee2e6;
  height: 2px;
  margin-top: 8px;
}
.side-menu-crud {
  width: calc(100% - 240px);
  max-width: calc(100% - 240px);
  // max-height: calc(100vh - 65px);
  // overflow: auto;
  padding: 0 15px;
  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 100%;
    padding: 0 0;
  }
}
// File Uploader Style
.file-uploader {
  position: relative;
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CED4DAFF' stroke-width='2' stroke-dasharray='4%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  label {
    position: static;
    background: transparent !important;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #001737;
    opacity: 0.5;
    height: auto;
  }
  .inputLabel {
    display: none !important;
  }
  .file-uploader-icon-wrap {
    margin-top: 0 !important;
    margin-bottom: 5px;
  }
  .file-uploader-wrap {
    margin-top: 0 !important;
    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100% !important;
      z-index: 9;
      left: 0;
      top: 0;
      cursor: auto;
    }
    .brows-file-btn {
      background: #5092a4 !important;
      border-radius: 30px;
      font-size: 14px;
      line-height: 24px;
      color: #f8f9fa;
      display: inline-block;
      opacity: 1;
      padding: 2px 33px;
      border: 2px solid #5092a4;
      position: relative;
      z-index: 99;
      cursor: pointer;
      &:hover {
        background: transparent !important;
        color: #5092a4;
      }
    }
  }
}
.inner-drop-wrap {
  .nav-link {
    padding: 0;
  }
  .dropdown-menu {
    &.show {
      position: static !important;
      // min-height: 200px !important;
      top: 0 !important;
      right: 0 !important;
      max-height: 300px;
      overflow: auto;
      box-shadow: unset;
      padding-bottom: 5px;
    }
  }
  .dropdown-toggle.nav-link[aria-expanded="true"] {
    img {
      transform: rotate(90deg);
    }
  }
}
.customDrop {
  .btn-primary:not([disabled]):not(.disabled):active {
    background-color: transparent !important;
  }
}

.border-right {
  border-right: 1px solid #ccc;
}

.customInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  padding-left: 10px;
  color: hsl(0, 0%, 50%);
}

.calenderIcon {
  position: absolute;
  right: 38px;
  top: 34%;
  cursor: pointer;
  &.pointerNone {
    pointer-events: none;
  }
}
input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    background: none !important;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 10px;
    height: 50%;
    width: 50%;
    margin-left: 0;
  }
}
// .custom_select {
//   .react-select__menu {
//     background-color: red;
//     z-index: 99;
//   }
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.clr-dark-blue{
  color: $dark-blue !important;
}